import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { above, color, smallBodyText } from '../../styles'
import cookie from '../../utils/cookie'
import useInternationalSettings from '../../hooks/useInternationalLanding'
import richTextRenderer from '../../utils/richTextRenderer'

const buttonFadeOut = keyframes`
  from {background-color: ${color.redPink}}
  to {background-color: transparent}
`
const buttonFadeIn = keyframes`
  from {background-color: transparent}
  to {background-color: ${color.redPink}}
`
const bannerFadeIn = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`
const bannerFadeOut = keyframes`
  from {opacity: 1}
  to {opacity: 0}
`

// eslint-disable-next-line react/prop-types
const ConsentBanner = ({ setShowBanner }) => {
  const [hasChosenCookieSettings, setHasChosenCookieSettings] = useState(false)
  const {
    cookiePolicyBannerDescription,
    cookiePolicyBannerAccept,
    cookiePolicyBannerDecline,
    cookiePolicyBannerAcceptAriaLabel,
    cookiePolicyBannerDeclineAriaLabel,
    // eslint-disable-next-line camelcase
    node_locale,
  } = useInternationalSettings()

  const handleOnClick = cookieValue => {
    setHasChosenCookieSettings(true)
    cookie.setConsentCookie(cookieValue)
    setTimeout(() => setShowBanner(false), 500)
  }
  return (
    <CookiesBanner
      data-cy="cookieconsent__banner"
      id="cookieconsent"
      animation={hasChosenCookieSettings ? bannerFadeOut : bannerFadeIn}
      opacity={hasChosenCookieSettings ? 0 : 1}
    >
      {richTextRenderer(cookiePolicyBannerDescription.json, node_locale)}
      <div>
        <button
          data-cy="cookieconsent__btn--decline"
          type="button"
          aria-label={cookiePolicyBannerDeclineAriaLabel}
          onClick={() => {
            handleOnClick('dismiss')
          }}
        >
          {cookiePolicyBannerDecline}
        </button>
        <button
          data-cy="cookieconsent__btn--accept"
          type="button"
          aria-label={cookiePolicyBannerAcceptAriaLabel}
          onClick={() => {
            handleOnClick('allow')
          }}
        >
          {cookiePolicyBannerAccept}
        </button>
      </div>
    </CookiesBanner>
  )
}

const CookiesBanner = styled.div`
  animation: ${props => props.animation} 1s;
  opacity: ${props => props.opacity};
  display: flex;
  position: fixed;
  z-index: 30;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${color.darkRed};
  color: ${color.lightPink};
  font-size: 16px;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  line-height: 24px;
  padding: 16px 29px;

  ${above.mobile`
 flex-direction: row;
 flex-wrap: nowrap;
`}

  span a {
    text-decoration: underline;
    opacity: 0.8;
    padding: 3px;
    :hover {
      opacity: 1;
    }
  }

  > div {
    padding-top: 16px;
    display: flex;
    flex-wrap: no-wrap;

    ${above.mobile`
  padding-top: 0;
  `}
  }

  p {
    margin: 0;
    ${smallBodyText}
  }

  a {
    text-decoration: underline;
    color: ${color.lightPink};
    opacity: 0.8;
  }

  button {
    animation: ${buttonFadeIn} 0.5s;
    color: ${color.lightPink};
    border-style: none;
    height: 40px;
    text-align: center;
    background-color: ${color.redPink};
    width: 140px;
    padding: 8px 12px;
    font-weight: 700;
    font-family: proxima-nova, sans-serif;
    font-size: 14px;

    margin-left: 7px;

    :hover {
      cursor: pointer;
      animation: ${buttonFadeOut} 0.5s;
      background-color: transparent;
      text-decoration: underline;
    }

    :focus {
      animation: ${buttonFadeOut} 0.5s;
      background-color: transparent;
    }
  }
`

export default ConsentBanner
