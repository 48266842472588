import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import arrowUp from '../assets/arrowDart.svg'
import { color, above, costaText, smallBodyText } from '../styles'

const Country = ({
  activeIndex,
  index,
  toggleOpen,
  name,
  web,
  phone,
  secondPhone,
  label,
  secondLabel,
  flag,
}) => {
  const isOpen = activeIndex === index

  const fireAnalytics = (country, currentCardIndex, cardIndex) => {
    if (typeof window !== 'undefined' && currentCardIndex !== cardIndex) {
      window.digitalData.page.category.country = country
      // eslint-disable-next-line no-unused-expressions
      window._satellite?.track('country-card-click')
    }
  }

  return (
    <>
      <CountryBox
        tabIndex="0"
        onKeyDown={e => {
          if (e.key === 'Enter') {
            toggleOpen(index)
          }
        }}
        onClick={() => {
          toggleOpen(index)
          fireAnalytics(name, activeIndex, index)
        }}
      >
        <Flag flag={flag} />
        <Heading3>{name}</Heading3>
        <Arrow className={isOpen ? 'open' : ''} />
      </CountryBox>
      {isOpen && (
        <Contact>
          {phone && (
            <p>
              <strong>{label || ''} </strong>
              <a className="phone" href={`tel: ${phone}`}>
                {phone}
              </a>
            </p>
          )}
          {secondPhone && (
            <p>
              <strong>{secondLabel || ''} </strong>
              <a className="phone" href={`tel: ${secondPhone}`}>
                {secondPhone}
              </a>
            </p>
          )}
          {web && (
            <p>
              <a href={`https://${web}`}>{web}</a>
            </p>
          )}
        </Contact>
      )}
      <Divider />
    </>
  )
}

Country.defaultProps = {
  activeIndex: 0,
  index: 0,
  toggleOpen: () => {},
  name: '',
  web: '',
  phone: 1234567890,
  secondPhone: 1234567890,
  label: '',
  secondLabel: '',
  flag: {},
}

Country.propTypes = {
  activeIndex: PropTypes.number,
  index: PropTypes.number,
  toggleOpen: PropTypes.func,
  name: PropTypes.string,
  web: PropTypes.string,
  phone: PropTypes.string,
  secondPhone: PropTypes.string,
  label: PropTypes.string,
  secondLabel: PropTypes.string,
  flag: PropTypes.string,
}

export default Country

const CountryBox = styled.div`
  display: flex;
  cursor: pointer;
`

const Heading3 = styled.h3`
  font-size: 20px;
  line-height: 22px;
  ${above.tablet`
  font-size: 30px;
  line-height: 36px;
  `}
`

const Flag = styled.div`
  display: inline-block;
  background: green;
  width: 28px;
  height: 28px;
  margin: auto 12px auto 0;
  border-radius: 50%;
  background: url(${p => p.flag});
  background-size: cover;
  background-position: center;

  ${above.tablet`
    margin: auto 20px auto 0;
    width: 40px;
    height: 40px;
  `}
`

const Arrow = styled.div`
  width: 8px;
  height: 10px;
  background-image: url(${arrowUp});
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(90deg);
  margin: auto 0 auto auto;

  &.open {
    transform: rotate(270deg);
  }
`

const Contact = styled.div`
  font-size: 14px;
  margin: 0 0 16px 40px;

  ${above.tablet`
    font-size: 16px;
    margin-left: 60px;
  `}

  p {
    ${smallBodyText}
    margin: 8px 0;
  }

  strong {
    ${costaText};
  }

  a {
    color: ${color.costaRed};
    ${costaText};

    &.phone {
      ${costaText};
      text-decoration: none;
      color: ${color.darkGrey};
    }
  }

  a:hover {
    color: ${color.brightRed};
  }
`

const Divider = styled.div`
  border-bottom: 2px solid #d8d8d8;
`
