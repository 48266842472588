import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { color, above } from '../styles'
import beanLogo from '../assets/3-bean-logo.png'
import Logo from './logo'

const InternationalHeader = ({ heroImage }) => {
  return (
    <Header heroImage={heroImage}>
      <StyledLogo>
        <Logo />
      </StyledLogo>
      <LogoIcon src={beanLogo} alt="Coffee Beans icon" />
    </Header>
  )
}

export default InternationalHeader

InternationalHeader.propTypes = {
  heroImage: PropTypes.string,
}

InternationalHeader.defaultProps = {
  heroImage: ""
}

const Header = styled.header`
  display: block;
  position: relative;
  height: 200px;
  background-color: ${color.costaRed};
  background-image: url(${p => p.heroImage});
  background-size: cover;
  background-position: center;
  color: ${color.white};
  text-align: center;
  overflow: hidden;

  ${above.tablet`
  
    height: 384px;
  `}

  ${above.tabletLarge`
    height: 376px;
  `}

  ${above.desktop`
    height: 464px;
  `}
`

const StyledLogo = styled.div`
  margin: 16px auto;
  width: 78px;
  height: 32px;
  display: block;
  position: relative;

  ${above.tablet`
    margin: 40px auto;
    width: 145px;
    height: 60px;

    > div {
      width: 145px;
      max-height: 60px;
    }
  `}

  ${above.desktop`
    margin: 40px auto;
    width: 240px;
    height: 99px;

    > div {
      width: 240px;
      max-height: 99px;
    }
  `}
`

const LogoIcon = styled.img`
  margin: 20px 15px;
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  bottom: 0;

  ${above.tablet`
    margin: 20px 30px;
    width: 80px;
    height: 80px;
  `}
`
