import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import GlobalStyles from '../styles/global'
import { color, above } from '../styles'
import useInternationalLanding from '../hooks/useInternationalLanding'
import Header from '../components/header'
import Country from '../components/country'
import ConsentBannerWrapper from '../components/ConsentBanner/consentBannerWrapper'
import AnalyticsDataLayer from '../utils/analyticsDataLayer'
import '../styles/css/fontface.css'

const LoadAdobe = oneTrustEnabled => {
  return (
    <Helmet>
      <script
        type={oneTrustEnabled ? 'text/plain' : 'text/javascript'}
        src={process.env.GATSBY_ADOBE_ANALYTICS_URL}
        className="optanon-category-C0002"
      />
    </Helmet>
  )
}

const InternationalLandingPage = () => {
  const {
    heading,
    heroImage,
    countries,
    seoTitle,
    seoDescription,
    noBackground,
  } = useInternationalLanding()

  const [activeIndex, setActiveIndex] = useState(null)
  const [allowTracking, setAllowTracking] = useState(false)

  const isProd = process.env.GATSBY_DEPLOY_ENVIRONMENT === 'production'

  const loadOneTrust =
    (process.env.GATSBY_ONETRUST_PROD === 'true' && isProd) ||
    (process.env.GATSBY_ONETRUST_DEV === 'true' && !isProd)

  const toggleOpen = index => setActiveIndex(activeIndex === index ? null : index)

  let location = { pathname: '' }
  if (typeof window !== 'undefined') {
    location = window.location ? window.location : { pathname: '' }
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en-GB',
        }}
        title={seoTitle}
        meta={[
          {
            name: `description`,
            content: seoDescription,
          },
        ]}
      />
      {loadOneTrust || (allowTracking && <LoadAdobe oneTrustEnabled={loadOneTrust} />)}
      <GlobalStyles noBackground={noBackground} bgColor={color.white} />
      {!loadOneTrust && <ConsentBannerWrapper setAllowTracking={setAllowTracking} />}
      <AnalyticsDataLayer location={location} locale="en-US" title={seoTitle} />
      <Header heroImage={heroImage.file.url} />
      <ContentBackgroundColor>
        <main>
          <LandingPageHeading>{heading}</LandingPageHeading>
          <List>
            {countries.map((country, index) => (
              <li key={country.id}>
                <Country
                  name={country.countryName}
                  web={country.web}
                  label={country.label}
                  secondLabel={country.secondLabel}
                  phone={country.phoneNumber}
                  secondPhone={country.secondPhoneNumber}
                  flag={country?.flag?.file?.url}
                  toggleOpen={toggleOpen}
                  index={index}
                  activeIndex={activeIndex}
                />
              </li>
            ))}
          </List>
        </main>
      </ContentBackgroundColor>
    </>
  )
}

export default InternationalLandingPage

const ContentBackgroundColor = styled.div`
  background-color: ${color.costaGrey};
  margin: 20px;
`

const LandingPageHeading = styled.h2`
  color: ${color.costaRed};
  margin: 25px auto 35px;
  max-width: 275px;
  text-align: center;
  padding-top: 24px;

  ${above.tablet`
    margin: 71px auto;
    max-width: 550px;
  `}

  ${above.tabletLarge`
    max-width: 800px;
  `}
`

const List = styled.ul`
  padding: 0 16px;
  margin: 0 auto 72px;
  max-width: 1200px;

  ${above.tablet`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 96px;
  `}

  li {
    list-style-type: none;
    text-align: left;

    ${above.tablet`
      padding: 0 16px;
      width: 50%;
  `}

    ${above.tabletLarge`
      width: 33%;
  `}
  }
`
