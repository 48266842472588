import { useStaticQuery, graphql } from 'gatsby'

const useInternationalSettings = () => {
  const { contentfulInternationalLanding } = useStaticQuery(graphql`
    query InternationalLandingQuery {
      contentfulInternationalLanding {
        heading
        heroImage {
          file {
            url
          }
        }
        seoTitle
        seoDescription
        cookiePolicyBannerDescription {
          json
        }
        cookiePolicyBannerDecline
        cookiePolicyBannerDeclineAriaLabel
        cookiePolicyBannerAccept
        cookiePolicyBannerAcceptAriaLabel
        countries {
          id
          countryName
          label
          secondLabel
          phoneNumber
          secondPhoneNumber
          web
          flag {
            file {
              url
            }
          }
        }
      }
    }
  `)

  return contentfulInternationalLanding
}

export default useInternationalSettings
