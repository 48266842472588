import { css } from 'styled-components'

export const costaText = css`
  font-family: 'Costa Text Regular', sans-serif;
  font-weight: normal !important;
`

export const costaTextBold = css`
  font-family: 'Costa Text Bold', sans-serif;
  font-weight: normal !important;
`
