/* eslint-disable import/prefer-default-export */
export const color = {
  alabaster: '#fafafa',
  black: '#000000',
  brightRed: '#b51144',
  costaGrey: '#f4f3ef',
  costaOrange: '#ce4b27',
  costaRed: '#6d1f37',
  darkGrey: '#404042',
  darkRed: '#91022E',
  disabledGrey: '#9b9b9b',
  empress: '#747474',
  focusBlue: '#0066ff',
  lightGrey: '#dddddd',
  lightPink: '#ffdddd',
  lightRed: '#cf1d1a',
  mediumGrey: '#9e9e9e',
  paleGrey: '#f4f3ef',
  redPink: '#bf0341',
  successGreen: '#4e8342',
  white: '#ffffff',
  whiteSmoke: '#f6f6f6',
}
