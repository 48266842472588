 /* eslint-disable */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { above } from '../styles'

export default ({ alt }) => {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      file(relativePath: { eq: "costa-coffee-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 456, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return <Logo fadeIn={false} loading="eager" fluid={data.file.childImageSharp.fluid} alt={alt} />
}

const Logo = styled(Img)`
  width: 78px;
  max-height: 35px;

  img {
    object-fit: contain !important;
  }

  ${above.desktop`
    width: 205px;
    max-height: 84px;
  `};
`
